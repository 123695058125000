// 技術参考
// https://hirakublog.com/loading-js/

export function initLoading(){
  
  const imagesLoaded = require('imagesloaded');
  
  const progressBar = document.querySelector('.js_loader_progress_bar')
  const progressNumber = document.querySelector('.js_loader_progress_number')
  
  //body内の画像を監視
  const imgLoad = imagesLoaded('body');
  
  //画像の総数を取得//画像の総数を取得
  const imgTotal = imgLoad.images.length;
  
  //ロードした画像カウント
  let imgLoaded = 0;
  
  //プログレスバーの進むスピード 大きいほど速くなる
  let progressSpeed = 8;
  
  //ローディングの進捗状況 0〜100
  let progressCount = 0;
  
  //ローディングの進捗状況 0〜100
  let progressResult = 0;

  //読み込み状況をアップデート
  let progressInit = setInterval(function () {
    updateProgress();
  }, 1);

  //画像を一つロードするたびにimgLoadedのカウントをアップ
  imgLoad.on('progress', function (instance, image) {
    imgLoaded++
  })

  //読み込み状況アップデート関数
  function updateProgress() {
    
    //ローディング演出用の計算
    progressCount += (imgLoaded / imgTotal) * progressSpeed;
    
    if(progressCount >= 100 && imgTotal > imgLoaded) {
      //カウントは100以上だが画像の読み込みが終わってない
      progressResult = 99;
    } else if(progressCount > 99.9) {
      //カウントが100以上になった
      progressResult = 100;
    } else {
      //現在のカウント
      progressResult = progressCount;
    }
    
    //ローディング進捗状況をプログレスバーと数字で表示
    progressBar.style.width = progressResult + '%';
    progressNumber.innerText = Math.floor(progressResult);
    
    //ローディング完了後 を表示
    if (progressResult >= 100 && imgTotal == imgLoaded) {
      clearInterval(progressInit);
      setTimeout(function () {
        document.querySelector('body').classList.add('js_isLoaded');
        
        //ローディングレイヤーを削除
        setTimeout(function () {
          document.getElementById('js_loader').remove();
        }, 800);
        
      }, 500);
    }
  }
  


  
}
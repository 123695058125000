//import Vue from 'vue';
import * as func from "./functions/_function";
import * as animation from "./functions/_animation";
//import * as bliderBox from "./functions/_bliderBox";
import * as layout from "./functions/_layout";
import * as offcanvas from "./functions/_offcanvas";
//import * as pagetop from "./functions/_pagetop";
//import * as smoothScroll from "./functions/_smoothScroll";
import * as loading from './functions/_loading';
import Modal from "../../node_modules/bootstrap/js/dist/modal";
import * as ua from './functions/_ua';
import slick from "./functions/slick/slick.min";

// グローバル関数
// ------------------------------------------------------------

let CURRENT_SCROLL_POS = 0;
let SCROLLDOWN_FLAG = true;

let WIN_WIDTH = window.innerWidth;
let WIN_HEIGHT = window.innerHeight;
let CURRENT_SC_OFFSET_X = window.pageXOffset;
let CURRENT_SC_OFFSET_Y = window.pageYOffset;
let BLAKEPOINT_HEADER_SP_MODE = 1024;


//ua.ua();  
animation.scrollObserver();
// loading.initLoading();                                                        // ローディングアニメーション
//headerFixed.setHeaderFixed();                                                 // ヘッダー固定
//smoothScroll.setSmoothScroll();                                               // スムーススクロール
offcanvas.setOffcanvas(BLAKEPOINT_HEADER_SP_MODE,'app');                      // オフキャンバス

window.addEventListener("scroll", () => {
  CURRENT_SC_OFFSET_X = window.pageXOffset;
  CURRENT_SC_OFFSET_Y = window.pageYOffset;

  //引数：固定,固定,ボタンを出現させるきっかけとなる要素のID名,ボタンを固定させるきっかけとなる要素のID名
  //pagetop.setScrolltopBtn(WIN_HEIGHT,CURRENT_SC_OFFSET_Y,'mv','footer');
  if (document.getElementById("js_scrollDown")) {
    let scrollDownElm = document.getElementById("js_scrollDown");

    let mvH = document.getElementById("js_fvSlider").getBoundingClientRect()
      .bottom;
    let interactiveBnrElm = document.getElementById("js_interactive_bnr01");

    if (window.scrollY > mvH / 2) {
      scrollDownElm.classList.add("js_anime_fadeOut");
      setTimeout(function () {
        scrollDownElm.style.display = "none";
      }, 1000);
    }
  }
});

window.addEventListener("resize", () => {
  WIN_WIDTH = window.innerWidth;
  WIN_HEIGHT = window.innerHeight;
});



$(function () {
  
  if ($(".is_home").length) {
    
    // hero部分のスライダーの記述
    // ------------------------------------------------------------

    $("#js_fvSlider").on('init',function(){
      $(this).addClass('is_init');
    });

    $("#js_fvSlider").slick({
      //slickを初期化
      fade: true, //切り替えをフェードで行う。初期値はfalse。
      autoplay: true, //自動的に動き出すか。初期値はfalse。
      autoplaySpeed: 1000, //次のスライドに切り替わる待ち時間
      speed: 2000, //スライドの動きのスピード。初期値は300。
      infinite: true, //スライドをループさせるかどうか。初期値はtrue。
      slidesToShow: 1, //スライドを画面に3枚見せる
      slidesToScroll: 1, //1回のスクロールで3枚の写真を移動して見せる
      arrows: false, //左右の矢印
      dots: false, //下部ドットナビゲーションの表示
      pauseOnFocus: false, //フォーカスで一時停止を無効
      pauseOnHover: false, //マウスホバーで一時停止を無効
      pauseOnDotsHover: false, //ドットナビゲーションをマウスホバーで一時停止を無効
      adaptiveHeight: true,
    });

    // hero部分下のintroduction_imgのslick処理
    // ------------------------------------------------------------

    $(".js_topSlider").slick({
      //slickを初期化
      fade: true, //切り替えをフェードで行う。初期値はfalse。
      autoplay: true, //自動的に動き出すか。初期値はfalse。
      autoplaySpeed: 1000, //次のスライドに切り替わる待ち時間
      speed: 2000, //スライドの動きのスピード。初期値は300。
      infinite: true, //スライドをループさせるかどうか。初期値はtrue。
      slidesToShow: 1, //スライドを画面に3枚見せる
      slidesToScroll: 1, //1回のスクロールで3枚の写真を移動して見せる
      arrows: false, //左右の矢印
      dots: false, //下部ドットナビゲーションの表示
      pauseOnFocus: false, //フォーカスで一時停止を無効
      pauseOnHover: false, //マウスホバーで一時停止を無効
      pauseOnDotsHover: false, //ドットナビゲーションをマウスホバーで一時停止を無効
      adaptiveHeight: true,
    });

    $(".js_topSlider02").slick({
      //slickを初期化
      fade: true, //切り替えをフェードで行う。初期値はfalse。
      autoplay: true, //自動的に動き出すか。初期値はfalse。
      autoplaySpeed: 3000, //次のスライドに切り替わる待ち時間
      speed: 2000, //スライドの動きのスピード。初期値は300。
      infinite: true, //スライドをループさせるかどうか。初期値はtrue。
      slidesToShow: 1, //スライドを画面に3枚見せる
      slidesToScroll: 1, //1回のスクロールで3枚の写真を移動して見せる
      arrows: false, //左右の矢印
      dots: false, //下部ドットナビゲーションの表示
      pauseOnFocus: false, //フォーカスで一時停止を無効
      pauseOnHover: false, //マウスホバーで一時停止を無効
      pauseOnDotsHover: false, //ドットナビゲーションをマウスホバーで一時停止を無効
      adaptiveHeight: true,
    });

    $(".js_topSlider02").on("beforeChange", function (nextSlide) {
      if (nextSlide > 0) {
        $(".js_topSlider02").slick("slickSetOption", "autoplaySpeed", 1000, true);
      }
    });

    // 
    // ------------------------------------------------------------

    $("#js_spaceSlider").slick({
      //slickを初期化
      fade: false, //切り替えをフェードで行う。初期値はfalse。
      autoplay: true, //自動的に動き出すか。初期値はfalse。
      autoplaySpeed: 2000, //次のスライドに切り替わる待ち時間
      speed: 3000, //スライドの動きのスピード。初期値は300。
      infinite: true, //スライドをループさせるかどうか。初期値はtrue。
      slidesToShow: 4, //スライドを画面に5枚見せる
      slidesToScroll: 1, //1回のスクロールで1枚の写真を移動して見せる
      arrows: false, //左右の矢印
      dots: false, //下部ドットナビゲーションの表示
      pauseOnFocus: false, //フォーカスで一時停止を無効
      pauseOnHover: false, //マウスホバーで一時停止を無効
      pauseOnDotsHover: false, //ドットナビゲーションをマウスホバーで一時停止を無効
      adaptiveHeight: true,
      centerMode: true,
      centerPadding: "12%",
      responsive: [
        {
          breakpoint: 1367,
          settings:{
            slidesToShow: 3,
          }
        },
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 3,
            centerMode: false,
          },
        },
        {
          breakpoint: 980,
          settings: {
            slidesToShow: 2,
            dots: true,
          },
        },
        {
          breakpoint: 601,
          settings: {
            slidesToShow: 1,
            dots: true,
            centerMode: true,
            centerPadding: "8%",
          },
        },
      ],
    });
  }
});

// TODO : 後に外部モジュール化
// ============================================================
// ローディング完了後にアニメーションを発火させる関数

function loadingCallbackAnimation(delay, cb) {

  console.log('animate')
  // ローディング完了フラグを監視
  var watchLoadedFlagClass = setInterval(function () {
    countUp();
  }, 1);

  var countUp = function countUp() {
    if ($('body').hasClass('js_isLoaded')) {
      setTimeout(function () {
        //引数で渡された関数を実行
        cb();
      }, delay);
      clearInterval(watchLoadedFlagClass);
    }
  };
}
 // ローディング時にコールバックさせるアニメーション関数をセット/制御


function setLoadingCbAnimation() {

  console.log('cb')
  //ターゲットクラスの有無を確認
  if ($('.js_loading_cb_animation').length) {
    var targetElms = $('.js_loading_cb_animation');
    targetElms.each(function (i) {
      var delay = $(this).data('animation-delay');
      $(this).delay(i * delay).queue(function (next) {
        $(this).addClass('js_isActive animate__animated');
        next();
      });
    });
  }
}

loadingCallbackAnimation(600, setLoadingCbAnimation);